<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>信息分类</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="wzright" style="float:right; margin-top:-20px;">
                <el-button type="primary" icon="el-icon-circle-plus-outline" @click="openadd">添加分类</el-button>
            </div>
        </div>
        <div class="box" style="margin-top:30px;">
            <div class="list" v-loading="loading">
                <el-table
                :data="list"
                border
                :header-cell-style="{'font-weight':'bold','color':'#555555','padding':'6px 0'}"
                stripe
                style="width: 100%">
                    <el-table-column
                    prop="id"
                    width="60"
                    align="center"
                    label="ID">
                    </el-table-column>
                    <el-table-column
                    prop="className"
                    label="分类名称">
                    </el-table-column>
                    <el-table-column
                    prop="contacts"
                    width="200"
                    label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary"  @click="openedit(scope.row)" style="margin-right:8px;">编辑</el-button>
                            <el-popconfirm
                            title="确定要删除吗？删除分类会删除其下的内容！"
                            @confirm="deleteclass(scope.row)">
                            <el-button type="danger"  slot="reference" >删除</el-button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <pop-up title="添加分类" width="26%" :isshow="addshow" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box">
                <el-input placeholder="分类名" v-model="addfrom.className"></el-input>
            </div>
        </pop-up>
        <pop-up title="编辑分类" width="26%" :isshow="editshow" :haveconfirm="true" @close="getclose2" @confirm="getconfirm2">
            <div slot="box">
                <el-input placeholder="分类名" v-model="editfrom.className"></el-input>
            </div>
        </pop-up>
    </div>
</template>
<script>
import apiurl from '@/api/information'
import _api from '@/api/index'
export default {
    data(){
        return{
            loading:false,
            addshow:false,
            editshow:false,
            addfrom:{
                className:""
            },
            editfrom:{
                id:"",
                className:"",
            },
            list:[]
        }
    },
    created(){
        this.getList()
    },
    methods:{
        getList(){//分类列表
            this.loading=true
            _api.get(apiurl.list,{}).then(res=>{
                console.log(res)
                this.loading=false
                if(res.success){
                    this.list=res.data
                }
            })
        },
        deleteclass(row){
            console.log(row.id)
            _api.get(apiurl.deleteclass,{id:row.id}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("删除成功！")
                    this.getList()
                }
            })
        },
        openedit(row){//打开编辑
            this.editshow=true
            this.editfrom.id=row.id
            this.editfrom.className=row.className
        },
        openadd(){
            this.addshow=true
        },
        getclose(){
            this.addshow=false
        },
        getclose2(){
            this.editshow=false
        },
        getconfirm2(data){//确认编辑
            this.editshow=false
            _api.get(apiurl.editclass,{id:this.editfrom.id,className:this.editfrom.className}).then(res=>{
                if(res.success){
                    this.$message.success("编辑成功!")
                    this.getList()
                }
            })
        },
        getconfirm(data){//添加分类
            this.addshow=false
            _api.get(apiurl.insert,this.addfrom).then(res=>{
                if(res.success){
                    this.$message.success("添加成功！")
                    this.addfrom.className=""
                    this.getList()
                }
            })
        }
    }
}
</script>
<style scoped>

</style>